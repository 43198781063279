import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Navigate } from 'react-router-dom';
import {
  getAssessmentPages,
  getAssessmentResults,
  getAssessmentWork,
  getModule,
  getUnit,
  getNextAssessmentRequestType,
  getAssessmentTtsData,
  getAssessmentType, isAssessmentClosed,
} from '../redux/Data/selectors';
import { isViewAsStudent } from '../redux/Authentication/selectors';
import {
  assessmentSaveSucceeded,
  finishAssessment,
  initiateAssessmentSave, logEvent,
  requestAssessmentData,
  requestAssessmentResults,
  requestLessonData,
  requestQuestionTts,
} from '../redux/Data/actions';
import AssessmentLearnosity from '../components/AssessmentLearnosity';
import AssessmentResults from '../components/AssessmentResults';
import AssessmentWarningModal from '../components/AssessmentWarningModal';
import LoadingSection from '../components/LoadingSection';
import { setLastActivity } from '../redux/Authentication/actions';
import ClosedMessage from '../components/ClosedMessage';
import Learnosity from '../services/learnosity';
import ErrorMessage from '../components/ErrorMessage';
import AssessmentResultsVaS from '../components/AssessmentResultsVaS';
import connectWithRouterMatch from '../redux/connectWithRouterMatch';

class Assessment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (Learnosity.hasInitializationErrors()) {
      this.handleInitErrors({ message: 'failed to initialize Learnosity' });
    }

    this.requestAssessmentData();

    const { assessmentComplete } = this.props;
    if (assessmentComplete) {
      this.resetViewAsStudent();
    }
  }

  componentDidUpdate({ nextRequest: prevRequest }) {
    const { nextRequest } = this.props;
    if (nextRequest !== prevRequest) {
      this.requestAssessmentData();
    }
  }

  handleAssessmentActivity() {
    const { assessmentWork, dispatch, viewAsStudent } = this.props;
    dispatch.setAssessmentActivity(assessmentWork.get('id'));
    if (!viewAsStudent) {
      dispatch.initiateAssessmentSave();
    }
  }

  handleInitErrors(error = {}) {
    const { dispatch } = this.props;
    const { code, message } = error;
    dispatch.logLearnosityInitError({ code, message });

    this.setState({ initError: true });
  }

  requestAssessmentData() {
    const {
      dispatch,
      nextRequest,
      type,
    } = this.props;

    const { initError } = this.state;

    if (initError) {
      return;
    }

    switch (nextRequest) {
      case 'lesson':
        dispatch.requestLessonData();
        break;
      case 'results':
        dispatch.requestAssessmentResults();
        break;
      default: break;
    }

    if (type === 'pre-test' && nextRequest === 'assessment') {
      dispatch.requestAssessmentData();
    }
  }

  resetViewAsStudent() {
    const { viewAsStudent, dispatch } = this.props;
    if (viewAsStudent) {
      dispatch.requestAssessmentData();
    }
  }

  render() {
    const {
      assessmentClosed,
      assessmentStarted,
      assessmentComplete,
      assessmentGrading,
      assessmentWork,
      gradingError,
      dispatch,
      history,
      learnosityRequest,
      nextRequest,
      page,
      pendingSave,
      results,
      title,
      type,
      ttsData,
      viewAsStudent,
      quizOverride,
      overrideEarned,
    } = this.props;
    const { initError } = this.state;

    if (assessmentClosed && !assessmentStarted) {
      return <ClosedMessage reasonCopy="Closed" messageType={type} classInfo="assignments" />;
    }

    if (gradingError) {
      return (
        <ErrorMessage title={`There was an error on your previous attempt for this ${type}.`}>
          <p>
            {`Ask your teacher to reset the ${type} and try again.`}
          </p>
        </ErrorMessage>
      );
    }

    if (assessmentComplete && !assessmentGrading) {
      if (viewAsStudent) {
        return <AssessmentResultsVaS type={type} retake={() => dispatch.requestAssessmentData()} />;
      }
      if (page !== 'results') {
        return <Navigate to="results" replace />;
      }

      return (
        <AssessmentResults
          assessmentClosed={assessmentClosed}
          results={results}
          type={type}
          work={assessmentWork}
          retake={() => dispatch.requestAssessmentData()}
          quizOverride={quizOverride}
          overrideEarned={overrideEarned}
        />
      );
    }

    if (page === 'results') { // assessment restarted, but we're still on results page
      return <Navigate to="." />;
    }

    if (initError) {
      return (
        <ErrorMessage title={`Your ${type} could not be started.`}>
          <p>
            Wait 5 seconds and reload this page.
          </p>
          <p>
            If the problem persists,
            {' '}
            have your teacher contact AES about URL allowlist requirements.
          </p>
        </ErrorMessage>
      );
    }

    if (learnosityRequest) {
      return (
        <AssessmentLearnosity
          requestString={learnosityRequest}
          pendingSave={pendingSave}
          ttsData={ttsData}
          finishAssessment={dispatch.finishAssessment}
          requestTts={dispatch.requestTts}
          setAssessmentActivity={() => this.handleAssessmentActivity()}
          onSaveSucceeded={dispatch.saveSucceeded}
          onInitError={(error) => this.handleInitErrors(error)}
        />
      );
    }

    if (type !== 'pre-test' && nextRequest === 'assessment') {
      return (
        <AssessmentWarningModal
          onClickStart={dispatch.requestAssessmentData}
          history={history}
          type={type}
          inProgress={assessmentStarted}
          title={title}
        />
      );
    }

    return <LoadingSection />;
  }
}

const mapStateToProps = (state, props) => {
  const {
    history,
    match: {
      params: {
        courseId,
        moduleKey,
        siteId,
        unitKey,
        '*': page,
      },
    },
  } = props;
  const mediaBaseUrl = process.env.REACT_APP_MEDIA_BASE_URL;
  const module = getModule(state, siteId, courseId, moduleKey);
  const unit = getUnit(state, siteId, courseId, moduleKey, unitKey);
  const type = getAssessmentType(module, unit);
  const ttsData = getAssessmentTtsData(state);
  const assessmentWork = getAssessmentWork(state, siteId, courseId, moduleKey, unitKey);
  const status = assessmentWork.get('status');
  const assessmentClosed = isAssessmentClosed(state, siteId, courseId, moduleKey, unitKey);
  const assessmentStarted = assessmentWork.has('status');
  const assessmentComplete = status > 1;
  const assessmentGrading = status > 2;
  const gradingError = status > 3;
  const assignmentId = module.get('id');
  const assignmentEntryId = unit.get('id');
  const pendingSave = assessmentWork.has('pendingSave');
  const results = getAssessmentResults(state, assignmentId, assignmentEntryId);
  const pageSet = getAssessmentPages(state, assignmentId, assignmentEntryId);
  const learnosityRequest = pageSet.get('learnosityRequest') || '';
  const title = unit.get('title') || module.get('title') || '';
  const nextRequest = getNextAssessmentRequestType(state, siteId, courseId, moduleKey, unitKey);
  const viewAsStudent = isViewAsStudent(state);
  const unitWork = unit.get('work') || Map();
  const quizOverride = !!unitWork.get('is_override') || false;
  const overrideEarned = assessmentWork.get('override_earned') || 0;

  return ({
    assessmentClosed,
    assessmentStarted,
    assessmentComplete,
    assessmentGrading,
    assessmentWork,
    gradingError,
    history,
    learnosityRequest,
    mediaBaseUrl,
    nextRequest,
    page,
    pageSet,
    pendingSave,
    results,
    title,
    type,
    ttsData,
    viewAsStudent,
    quizOverride,
    overrideEarned,
  });
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    match: {
      params: {
        siteId,
        courseId,
        moduleKey,
        unitKey,
      },
    },
  } = props;
  return ({
    dispatch: {
      finishAssessment: (learnositySession) => dispatch(finishAssessment(learnositySession)),
      initiateAssessmentSave: () => dispatch(initiateAssessmentSave(
        siteId,
        courseId,
        moduleKey,
        unitKey,
      )),
      requestAssessmentData: () => dispatch(requestAssessmentData(
        siteId,
        courseId,
        moduleKey,
        unitKey,
      )),
      requestAssessmentResults: () => dispatch(requestAssessmentResults(
        siteId,
        courseId,
        moduleKey,
        unitKey,
      )),
      requestLessonData: () => dispatch(requestLessonData(
        siteId,
        courseId,
        moduleKey,
        unitKey,
      )),
      requestTts: (question) => dispatch(requestQuestionTts(siteId, question)),
      saveSucceeded: () => dispatch(assessmentSaveSucceeded(
        siteId,
        courseId,
        moduleKey,
        unitKey,
      )),
      setAssessmentActivity: (assessmentWorkId) => dispatch(setLastActivity({
        type: 'assessment',
        assessmentWorkId,
      })),
      logLearnosityInitError: (error) => dispatch(logEvent(siteId, courseId, 'error_learnosity_init', { error_context: error })),
    },
  });
};

Assessment.propTypes = {
  assessmentClosed: PropTypes.bool.isRequired,
  assessmentStarted: PropTypes.bool.isRequired,
  assessmentComplete: PropTypes.bool.isRequired,
  assessmentGrading: PropTypes.bool.isRequired,
  assessmentWork: PropTypes.instanceOf(Map).isRequired,
  gradingError: PropTypes.bool.isRequired,
  dispatch: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  learnosityRequest: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  pendingSave: PropTypes.bool.isRequired,
  results: PropTypes.instanceOf(Map).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  ttsData: PropTypes.instanceOf(Map).isRequired,
  nextRequest: PropTypes.string.isRequired,
  viewAsStudent: PropTypes.bool.isRequired,
  quizOverride: PropTypes.bool.isRequired,
  overrideEarned: PropTypes.number.isRequired,
};

export default connectWithRouterMatch(mapStateToProps, mapDispatchToProps)(Assessment);
